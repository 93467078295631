import React from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import store from 'store'
import { ApolloProvider } from '@apollo/client'
import apolloClient from 'providers/apolloClient'
import PreviewerScreenBase from '@apphiveio/controlsmanager/components/PreviewerScreenBase'
import MobileFrame from 'components/atoms/MobileFrame'
import PreviewerAppContext from 'components/organisms/PreviewerAppContext'
import PreviewerRenderer from 'components/organisms/PreviewerRenderer'
import ToastWrapper from 'components/atoms/ToastWrapper'

const App = () => (
    <ApolloProvider client={apolloClient}>
        <ReduxProvider store={store}>
            <PreviewerAppContext>
                <MobileFrame>
                    <PreviewerScreenBase>
                        <PreviewerRenderer />
                        <ToastWrapper id="toast-container" />
                    </PreviewerScreenBase>
                </MobileFrame>
            </PreviewerAppContext>
        </ReduxProvider>
    </ApolloProvider>
)

export default App
