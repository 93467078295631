import React from 'react'
import SvgIcon from '@apphiveio/controlsmanager/components/SvgIcon'
import { FloatingAction, FloatingContainer } from 'components/atoms/FloatingActionButton'
import logo from '../../../assets/images/logoApphive.png'
import { Image, Label } from './style'

type Props = {
    onReturn: () => void;
}

const RendererApplicationMenu: React.VFC<Props> = ({ onReturn }) => (
    <FloatingContainer>
        <FloatingAction onClick={onReturn}>
            <SvgIcon
                name="keyboard-return"
                family="MaterialCommunityIcons"
                size={18}
                color="white"
            />
            <Label>Return</Label>
        </FloatingAction>
        <FloatingAction>
            <Image src={logo} alt="apphive" />
        </FloatingAction>
    </FloatingContainer>
)

export default RendererApplicationMenu
