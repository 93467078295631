import getValueByPathFromUnknown from '@apphiveio/controlsmanager/shared/getValueByPathFromUnknown'
import { odCreatorApp } from 'firebaseConnection'

const renderForgotPassword = async (
    email: string,
): Promise<{ status: boolean; messageError?: string}> => {
    try {
        await odCreatorApp.auth().sendPasswordResetEmail(email)
        return { status: true }
    } catch (error) {
        const message = getValueByPathFromUnknown(error, ['message'])
        return { status: false, messageError: `${message}` }
    }
}

export default renderForgotPassword
