import React, { useCallback } from 'react'
import PreviewerSignUpScreen from '@apphiveio/controlsmanager/components/PreviewerSignUpScreen'
import windowAlert from 'shared/windowAlert'
import getValueByPathFromUnknown from '@apphiveio/controlsmanager/shared/getValueByPathFromUnknown'
import { rendererEmailSignUp } from 'shared/rendererAuthentication'
import { PreviewerBooterProps } from 'components/organisms/PreviewerBooter'

type User = {
    email: string,
    name: string,
    password: string,
    phone: string,
}

const SignUpScreenWithEmail: React.VFC<PreviewerBooterProps> = ({ navigation }) => {
    const onSignUp = useCallback(async (userInfo: User) => {
        try {
            const {
                name, password, email, phone,
            } = userInfo

            const user = await rendererEmailSignUp({
                name,
                email,
                phone,
                password,
            })
            if (user) {
                navigation.replace({ pageId: 'projectsList' })
            }
        } catch (error) {
            const errorMessage = getValueByPathFromUnknown(error, ['message'])
            windowAlert(
                'Error',
                `${errorMessage}`,
            )
        }
    }, [navigation])

    return (
        <PreviewerSignUpScreen
            isKeyboardOpen={false}
            onSignUpProps={onSignUp}
            onGoBack={navigation.goBack}
            onChangeToEmailAuth={() => {
                navigation.replace({ pageId: 'auth' })
                navigation.push({ pageId: 'emailAuth' })
            }}
        />
    )
}

export default SignUpScreenWithEmail
