import { gql, useQuery } from '@apollo/client'
import {
    ClientProjectRecord, ClientApplicationRecord, AuthUserRecord,
} from '@apphiveio/controlsmanager/types/Server'
import { onApolloError } from 'providers/apolloClient'

const LIST_MY_PROJECTS = gql`query { authUser {
    id
    phoneId
    phoneVerificationIsRequired
    projects {
      id
      name
      apps {
          id
          iconUrl
          name
      }
      testerApps {
          id
          iconUrl
          name
      }
    }
} }`

type HomeGQLState = {
    authUser: Pick<AuthUserRecord, 'id' | 'phoneId' | 'phoneVerificationIsRequired'> & {
        projects: (Pick<ClientProjectRecord, 'id' | 'name'> & {
            apps: Pick<ClientApplicationRecord, 'id' | 'iconUrl'>[]
            testerApps: Pick<ClientApplicationRecord, 'id' | 'iconUrl'>[]
        })[]
    }
}

const useGraphQLState = () => {
    const {
        data,
        loading,
    } = useQuery<HomeGQLState>(LIST_MY_PROJECTS, {
        onError: onApolloError,
        fetchPolicy: 'network-only',
    })

    return {
        data,
        loading,
    }
}

export default useGraphQLState
