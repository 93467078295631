import React from 'react'
import { ApplicationRoute } from '@apphiveio/controlsmanager/types/AppRouting'
import { PreviewerNavigation } from '@apphiveio/controlsmanager/shared/buildPreviewerNavigation'
import RendererSplashScreen from 'components/pages/RendererSplashScreen'
import AuthenticationScreen from 'components/pages/AuthenticationScreen'
import SignUpScreenWithEmail from 'components/pages/SignUpScreenWithEmail'
import ProjectListScreen from 'components/pages/ProjectListScreen'
import AppsListScreen from 'components/pages/AppsListScreen'
import AuthWithEmailScreen from 'components/pages/AuthWithEmailScreen'
import ForgotPasswordScreen from 'components/pages/ForgotPasswordScreen'
import ProcessorAppBooter from '../ProcessorAppBooter'

export type PreviewerBooterProps = {
    route: ApplicationRoute;
    navigation: PreviewerNavigation;
}

const PreviewerBooter: React.VFC<PreviewerBooterProps> = ({ route, navigation }) => {
    const current = route.pageId

    if (current === 'splashScreen') {
        return <RendererSplashScreen route={route} navigation={navigation} />
    }

    if (current === 'auth') {
        return <AuthenticationScreen route={route} navigation={navigation} />
    }

    if (current === 'signUp') {
        return <SignUpScreenWithEmail route={route} navigation={navigation} />
    }

    if (current === 'projectsList') {
        return <ProjectListScreen route={route} navigation={navigation} />
    }

    if (current === 'appsList') {
        return <AppsListScreen route={route} navigation={navigation} />
    }

    if (current === 'clientApp') {
        return <ProcessorAppBooter route={route} navigation={navigation} />
    }

    if (current === 'emailAuth') {
        return <AuthWithEmailScreen route={route} navigation={navigation} />
    }

    if (current === 'forgotPassword') {
        return <ForgotPasswordScreen route={route} navigation={navigation} />
    }

    return null
}

export default PreviewerBooter
