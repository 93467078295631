import styled from 'styled-components/macro'
import { PreviewerTheme } from '@apphiveio/controlsmanager/PreviewerContext'

export const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    
`

export const Label = styled.span`
    font-size: 11px;
    color: ${PreviewerTheme.colors.primary.text};
`
