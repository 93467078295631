import React, { useCallback, useState } from 'react'
import PreviewerAuthenticationScreen from '@apphiveio/controlsmanager/components/PreviewerAuthenticationScreen'
import getValueByPathFromUnknown from '@apphiveio/controlsmanager/shared/getValueByPathFromUnknown'
import PreviewerPageSplash from '@apphiveio/controlsmanager/components/PreviewerPageSplash'
import { PreviewerBooterProps } from 'components/organisms/PreviewerBooter'
import windowAlert from 'shared/windowAlert'
import { rendererGoogleLogin, rendererFacebookLogin } from 'shared/rendererAuthentication'

const AuthenticationScreen: React.VFC<PreviewerBooterProps> = ({ navigation }) => {
    const [loadingMessage, setLoadingMessage] = useState<undefined | string>(undefined)

    const loginWithGoogle = useCallback(async () => {
        try {
            setLoadingMessage('Logging with Google')
            const currentUser = await rendererGoogleLogin()
            if (currentUser) {
                navigation.replace({ pageId: 'projectsList' })
            }
        } catch (error: unknown) {
            const errorMessage = getValueByPathFromUnknown(error, ['message'])
            windowAlert('Error', `${errorMessage}`)
        } finally {
            setLoadingMessage(undefined)
        }
    }, [navigation])

    const loginWithFacebook = useCallback(async () => {
        try {
            setLoadingMessage('Logging with Facebook')
            const currentUser = await rendererFacebookLogin()
            if (currentUser) {
                navigation.replace({ pageId: 'projectsList' })
            }
        } catch (error: unknown) {
            const errorMessage = getValueByPathFromUnknown(error, ['message'])
            windowAlert('Error', `${errorMessage}`)
        } finally {
            setLoadingMessage(undefined)
        }
    }, [navigation])

    if (loadingMessage) {
        return <PreviewerPageSplash message={loadingMessage} />
    }

    return (
        <PreviewerAuthenticationScreen
            onLoginWithFacebook={loginWithFacebook}
            onLoginWithGoogle={loginWithGoogle}
            onRedirectToTermsAndConditions={() => {
                window.open('https://apphive.io/es/terminos-y-condiciones', '_blank')
            }}
        />
    )
}

export default AuthenticationScreen
