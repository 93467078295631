import React from 'react'
import PreviewerPageSplash from '@apphiveio/controlsmanager/components/PreviewerPageSplash'
import PreviewerAppsListScreen from '@apphiveio/controlsmanager/components/PreviewerAppsListScreen'
import { PreviewerBooterProps } from 'components/organisms/PreviewerBooter'
import useGraphQLState from './graphql'

const AppsListScreen: React.VFC<PreviewerBooterProps> = ({ route, navigation }) => {
    const projectId = `${route.params.projectId}`
    const { data: projectData, loading } = useGraphQLState({ projectId })

    if (loading) {
        return <PreviewerPageSplash message="Loading applications" />
    }

    return (
        <PreviewerAppsListScreen
            projectData={projectData}
            onBack={navigation.goBack}
            onRedirect={({ id }) => {
                navigation.replace({ pageId: 'clientApp', params: { appId: id, projectId } })
            }}
        />
    )
}

export default AppsListScreen
