// THIS VALUES ARE USED DURING CLIENT APP COMPILATION, PLEASE DO NOT MOVE
const initialAppId = undefined
const initialProjectId = undefined
const initialBuildOrdersOnApp = undefined
const initialRunningMode = undefined
const initialCustomFontName = undefined
// #################

export const initialAppState = {
    appId: initialAppId,
    projectId: initialProjectId,
    buildOrders: initialBuildOrdersOnApp,
    runningMode: initialRunningMode,
    customFontName: initialCustomFontName,
} as const
