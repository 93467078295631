import { gql, useQuery } from '@apollo/client'
import { ClientApplicationRecord, ClientProjectRecord } from '@apphiveio/controlsmanager/types/Server'
import { onApolloError } from '../../../providers/apolloClient'

const CLIENT_PROJECT = gql`query ClientProject($projectId: String!) {
    clientProject(id: $projectId) {
        id
        name
        apps {
            id
            name
            iconUrl
            isBlocked
        }
        testerApps {
            id
            name
            iconUrl
            isBlocked
        }
    }
}`

type ClientProjectArgs = {
    projectId: string;
}

type ClientProjectReturn = {
    clientProject: (Pick<ClientProjectRecord, 'id' | 'name'> & {
        apps: Pick<ClientApplicationRecord, 'id' | 'name' | 'iconUrl' | 'isBlocked'>[];
        testerApps: Pick<ClientApplicationRecord, 'id' | 'name' | 'iconUrl' | 'isBlocked'>[];
    });
}

const useGraphQLState = ({ projectId }: ClientProjectArgs) => (
    useQuery<ClientProjectReturn, ClientProjectArgs>(CLIENT_PROJECT, {
        onError: onApolloError,
        variables: { projectId },
        fetchPolicy: 'network-only',
    })
)

export default useGraphQLState
