import React from 'react'
import { useSelector } from 'react-redux'
import store from 'store'
import { odCreatorApp } from 'firebaseConnection'
import { ApphiveRendererState } from 'store/reducers'
import Previewer from '@apphiveio/controlsmanager/components/Previewer'
import { buildPreviewerUpdateSideMenuAction } from '@apphiveio/controlsmanager/previewerState/previewerStateActionBuilders'
import PreviewerBooter from './PreviewerBooter'

const PreviewerRenderer: React.VFC = () => {
    const routeStack = useSelector((
        state: ApphiveRendererState,
    ) => state.previewer.routeStack) ?? []
    const sideMenu = useSelector((state: ApphiveRendererState) => state.previewer.sideMenu)
    return (
        <Previewer
            sideMenu={sideMenu}
            routeStack={routeStack}
            rendererPage={(route, navigation) => (
                <PreviewerBooter route={route} navigation={navigation} />
            )}
            onSetIsSideMenuOpened={(isOpened) => {
                store.dispatch(buildPreviewerUpdateSideMenuAction({
                    isOpened: !!isOpened,
                }))
            }}
            onSetTransitionSideMenu={(newState) => {
                store.dispatch(buildPreviewerUpdateSideMenuAction({
                    transition: newState?.type,
                    speed: newState?.speed,
                }))
            }}
            onSharedUserUid={() => {
                const textField = document.createElement('textarea')
                textField.innerText = odCreatorApp.auth().currentUser?.uid || ''
                document.body.appendChild(textField)
                textField.select()
                document.execCommand('copy')
                textField.remove()
            }}
        />
    )
}

export default PreviewerRenderer
