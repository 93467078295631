import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import store from 'store'
import { buildPreviewerUpdateSideMenuAction } from '@apphiveio/controlsmanager/previewerState/previewerStateActionBuilders'
import PreviewerProjectsListScreen from '@apphiveio/controlsmanager/components/PreviewerProjectsListScreen'
import PreviewerPageSplash from '@apphiveio/controlsmanager/components/PreviewerPageSplash'
import PreviewerFaqScreen from '@apphiveio/controlsmanager/components/PreviewerFaqScreen'
import windowAlert from 'shared/windowAlert'
import { selectCurrentRendererUser } from 'store/selectors'
import { PreviewerBooterProps } from 'components/organisms/PreviewerBooter'
import useGraphQLState from './graphql'

const ProjectListScreen: React.VFC<PreviewerBooterProps> = ({ navigation }) => {
    const { data: graphQLState, loading } = useGraphQLState()
    const currentUser = useSelector(selectCurrentRendererUser)

    const projects = graphQLState?.authUser.projects || []

    useEffect(() => {
        if (!currentUser) {
            navigation.replace({ pageId: 'auth' })
            return
        }
        if (!loading && !graphQLState?.authUser?.phoneId
            && graphQLState?.authUser.phoneVerificationIsRequired) {
            windowAlert('Redirect', 'verifyPhone')
        }
    }, [
        currentUser,
        loading,
        graphQLState?.authUser?.phoneId,
        graphQLState?.authUser.phoneVerificationIsRequired,
        navigation,
    ])

    const onToggleSideMenu = () => {
        store.dispatch(buildPreviewerUpdateSideMenuAction({
            isOpened: true,
            transition: 'SlideRight',
            speed: 'Fast',
        }))
    }

    if (loading) {
        return <PreviewerPageSplash message="Loading projects" />
    }

    if (!projects.length) {
        return (
            <PreviewerFaqScreen onToggleSideMenu={onToggleSideMenu} />
        )
    }

    return (
        <PreviewerProjectsListScreen
            projects={projects}
            onToggleSideMenu={onToggleSideMenu}
            onNavigateProject={(projectId) => navigation.push({ pageId: 'appsList', params: { projectId } })}
        />
    )
}

export default ProjectListScreen
