import React, { useCallback, useState } from 'react'
import PreviewerForgotPasswordScreen from '@apphiveio/controlsmanager/components/PreviewerForgotPasswordScreen'
import renderForgotPassword from 'shared/renderForgotPassword'
import stringIsValidEmailAddress from '@apphiveio/controlsmanager/shared/stringIsValidEmailAddress'
import PreviewerPageSplash from '@apphiveio/controlsmanager/components/PreviewerPageSplash'
import { PreviewerBooterProps } from 'components/organisms/PreviewerBooter'
import windowAlert from 'shared/windowAlert'

const ForgotPasswordScreen: React.VFC<PreviewerBooterProps> = ({ navigation }) => {
    const [email, setEmail] = useState('')
    const [loadingMessage, setLoadingMessage] = useState<undefined | string>(undefined)

    const onSubmitEmail = useCallback(() => {
        if (!stringIsValidEmailAddress(email)) {
            windowAlert('Error', 'Invalid email')
            return
        }
        if (email) {
            setLoadingMessage('Loading Forgot Password...')
            renderForgotPassword(email)
                .then(({ status, messageError }) => {
                    if (status) {
                        windowAlert('Success', 'Check your email inbox')
                    }
                    if (messageError) {
                        windowAlert('Error', messageError)
                    }
                })
                .finally(() => {
                    setLoadingMessage(undefined)
                })
        }
    }, [email])

    if (loadingMessage) {
        return <PreviewerPageSplash message={loadingMessage} />
    }

    return (
        <PreviewerForgotPasswordScreen
            isKeyboardOpen={false}
            email={email}
            onBack={navigation.goBack}
            onSetEmail={setEmail}
            onSubmitEmail={onSubmitEmail}
        />
    )
}

export default ForgotPasswordScreen
