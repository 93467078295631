import React, { useEffect, useState } from 'react'
import PreviewerPageSplash from '@apphiveio/controlsmanager/components/PreviewerPageSplash'
import { Processor } from '@apphiveio/controlsmanager/types/Runtime'
import { odCreatorApp } from 'firebaseConnection'
import valueIsBuildOrders from '@apphiveio/controlsmanager/typeAssertions/valueIsBuildOrders'
import RendererApplicationMenu from 'components/molecules/RendererApplicationMenu'
import { PreviewerBooterProps } from 'components/organisms/PreviewerBooter'
import subscribeToRealtimeEventBus from 'shared/subscribeToRealtimeEventBus'
import { RealTimeEventBusTypes } from '@apphiveio/controlsmanager/firebase/getPathToRealTimeEventBusValue'
import appStateBuildGetStoreHook from '@apphiveio/controlsmanager/shared/appStateBuildGetStoreHook'
import buildApplicationProcessor from 'shared/buildApplicationProcessor'
import AppBooter from './AppBooter'
import { loadMainAuthSession } from '../../shared/rendererAuthentication'
import webappFunctions from './WebappContext/webappFunctions'

const useStore = appStateBuildGetStoreHook({
    getInitialBO: async ({
        appId,
        projectId,
    }) => {
        const boReference = odCreatorApp.database().ref(`projects/${projectId}/apps/${appId}/buildOrders`)

        const snap = await boReference.once('value')

        const rawSnapValue = snap.val()

        if (!valueIsBuildOrders(rawSnapValue)) {
            throw new Error('Invalid build orders')
        }

        return rawSnapValue
    },
    subscribeToBORequestRestart: ({
        appId,
        listener,
    }) => subscribeToRealtimeEventBus({
        type: RealTimeEventBusTypes.CLIENT_APP_BUILD_ORDERS_CHANGED,
        payload: { id: appId },
    }, listener),
    functions: {
        areEnabled: true,
        definitions: webappFunctions,
    },
    interfaces: {
        localStorage: {
            getItem: async (key) => window.localStorage.getItem(key),
            removeItem: async (key) => window.localStorage.removeItem(key),
            setItem: async (key, value) => window.localStorage.setItem(key, value),
        },
    },
})

const ProcessorAppBooter: React.VFC<PreviewerBooterProps> = ({ route, navigation }) => {
    const [processor, setProcessor] = useState<Processor | undefined>(undefined)
    const appId = `${route.params.appId}`
    const projectId = `${route.params.projectId}`
    const appStateStore = useStore({
        appId,
        projectId,
    })

    const [isLoadingMainSession, setIsLoadingMainSession] = useState(false)

    useEffect(() => {
        if (!appStateStore) {
            return
        }
        buildApplicationProcessor(appStateStore).then(setProcessor)
    }, [appStateStore])

    useEffect(() => () => {
        if (!processor) {
            return
        }
        processor.destroy()
    }, [processor])

    if (!appStateStore || isLoadingMainSession || !processor) {
        return <PreviewerPageSplash message="Loading app" />
    }

    return (
        <>
            <AppBooter
                appStateStore={appStateStore}
                processor={processor}
                onRequestRestart={() => {
                    window.location.reload()
                }}
            />
            <RendererApplicationMenu
                onReturn={async () => {
                    setIsLoadingMainSession(true)
                    await loadMainAuthSession()
                    navigation.replace({ pageId: 'projectsList' })
                    navigation.push({ pageId: 'appsList', params: { projectId } })
                }}
            />
        </>
    )
}

export default ProcessorAppBooter
