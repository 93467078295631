import React, { useEffect } from 'react'
import PreviewerRendererSplashScreen from '@apphiveio/controlsmanager/components/PreviewerRendererSplashScreen'
import { PreviewerBooterProps } from 'components/organisms/PreviewerBooter'
import { loadMainAuthSession } from 'shared/rendererAuthentication'

const RendererSplashScreen: React.VFC<PreviewerBooterProps> = ({ navigation }) => {
    useEffect(() => {
        loadMainAuthSession()
            .then((rendererUser) => {
                if (!rendererUser) {
                    navigation.replace({ pageId: 'auth' })
                } else {
                    navigation.replace({ pageId: 'projectsList' })
                }
            })
    }, [navigation])

    return (
        <PreviewerRendererSplashScreen />
    )
}

export default RendererSplashScreen
