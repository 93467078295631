import React, { useCallback, useState } from 'react'
import windowAlert from 'shared/windowAlert'
import PreviewerPageSplash from '@apphiveio/controlsmanager/components/PreviewerPageSplash'
import PreviewerAuthWithEmailScreen from '@apphiveio/controlsmanager/components/PreviewerAuthWithEmailScreen'
import { PreviewerBooterProps } from 'components/organisms/PreviewerBooter'
import { rendererEmailLogin } from 'shared/rendererAuthentication'

const AuthWithEmailScreen: React.VFC<PreviewerBooterProps> = ({ navigation }) => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const onSubmitPassword = useCallback(async () => {
        if (!email) {
            windowAlert('Warning', 'Email required')
            return
        }
        if (!password) {
            windowAlert('Warning', 'Password required')
            return
        }

        try {
            setIsLoading(true)
            const currentUser = await rendererEmailLogin(email, password)
            if (currentUser) {
                navigation.replace({ pageId: 'projectsList' })
            }
        } catch {
            windowAlert('Error', 'Could not log in')
        } finally {
            setIsLoading(false)
        }
    }, [email, password, navigation])

    if (isLoading) {
        return <PreviewerPageSplash message="Logging In" />
    }

    return (
        <PreviewerAuthWithEmailScreen
            email={email}
            password={password}
            isKeyboardOpen
            onSubmitEmail={() => undefined}
            onSubmitPassword={onSubmitPassword}
            onGoBack={navigation.goBack}
            onNavigateForgotPassword={() => navigation.push({ pageId: 'forgotPassword' })}
            onNavigateSignUp={() => navigation.push({ pageId: 'signUp' })}
            onSetEmail={setEmail}
            onSetPassword={setPassword}
        />
    )
}

export default AuthWithEmailScreen
