import styled from 'styled-components/macro'
import { PreviewerTheme } from '@apphiveio/controlsmanager/PreviewerContext'

export const FloatingContainer = styled.nav`
    bottom: 10vh;
    position: fixed;
    margin: 1em;
    right: 8vw;

    :hover {
        button:not(:last-child) {
            height: 60px;
            opacity: 1;
            transform: none;
            margin: 15px auto 0;
        };

        button:not(:last-child) i {
            opacity: 1;
        };
    };
    
`

export const FloatingAction = styled.button`
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    border-radius: 50%;
    border-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    margin: 20px auto 0;
    position: relative;
    transition: transform .1s ease-out, height 100ms ease, opacity 100ms ease;
    text-decoration: none;
    background-color: ${PreviewerTheme.colors.primary.main};

    :hover {
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
        transition: box-shadow .2s ease;
        outline: none;
    };

    :active {
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
        transition: box-shadow .2s ease;
        outline: none;
    };
    
    :focus {
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
        transition: box-shadow .2s ease;
        outline: none;
    };

    :not(:last-child) {
        width: 60px;
        height: 0px;
        margin: 0px auto 0;
        opacity: 0;
        transform: translateY(50px);
    };

    :not(:last-child) i {
        opacity: 0;
    };

    :nth-last-child(1) {
        transition-delay: 40ms;
        background-size: contain;
    };
`
